import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Obfuscate from "react-obfuscate"
import { isValidPhoneNumber } from "react-phone-number-input"
import PhoneInput from "react-phone-number-input/input"
import Fade from "react-reveal/Fade"

const ContactForm = () => {
    const [checkboxChecked, setCheckboxChecked] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [fail, setFail] = useState()
    const [success, setSuccess] = useState()
    const [data, setData] = useState({})

    const onSubmit = () => {
        const newErrors = {}

        const { email, firstName, message, phoneNumber } = data
        setIsLoading(true)
        if (!firstName?.length) {
            newErrors.firstName = "Required"
        }
        if (!email?.length) {
            newErrors.email = "Required"
        } else {
            var re = /\S+@\S+\.\S+/
            if (!re.test(email)) {
                newErrors.email = "Example: name@example.com"
            }
        }
        if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
            newErrors.phoneNumber = "Example: +44 8870 587 125"
        }

        if (!message?.length) {
            newErrors.message = "Please type a message"
        }

        if (!Object.keys(newErrors).length) {
            sendMessage()
            setErrors({})
        } else {
            setErrors(newErrors)
            setIsLoading(false)
        }
    }

    async function sendMessage() {
        const { email, firstName, message, phoneNumber } = data

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                firstName,
                email,
                phoneNumber,
                message,
                subscribe: checkboxChecked
            })
        }
        try {
            const response = await fetch("https://maliblueyachts-back-end-production.azurewebsites.net/contact", requestOptions)
            if (response.ok) {
                setFail(null)
                setSuccess(true)
                setData()
            } else {
                setSuccess(null)
                setFail(true)
            }
            setIsLoading(false)
        } catch (error) {
            setSuccess(null)
            setFail(true)
            setIsLoading(false)
        }
    }

    return (
        <div className="container-xxl py-contact-form">
            <div className="row ">
                <div className="col-12 px-0 mb-4 col-md-7 order-md-2 mb-md-0 pe-inside">
                    <Fade duration={1500} delay={400}>
                        <div className="contact-maliblue-block px-md-5 form py-5">
                            <h2 className="text-center text-white  pb-2">Get in touch</h2>
                            <div className="row gy-3">
                                <div className="col-12 ">
                                    <div className="form-floating">
                                        <input
                                            value={data?.firstName ?? ""}
                                            type="text"
                                            className={"form-control" + (errors.firstName ? " error" : "")}
                                            id="firstName"
                                            placeholder="First Name"
                                            onChange={e => setData(d => ({ ...d, firstName: e.target.value }))}
                                        />
                                        <label htmlFor="firstName">First Name</label>
                                        {errors.firstName && <span className="form-error-message">{errors.firstName}</span>}
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="form-floating">
                                        <input
                                            value={data?.email ?? ""}
                                            type="text"
                                            className={"form-control" + (errors.email ? " error" : "")}
                                            id="email"
                                            placeholder="Email Address"
                                            onChange={e => setData(d => ({ ...d, email: e.target.value }))}
                                        />
                                        <label htmlFor="email">Email Address</label>
                                        {errors.email && <span className="form-error-message">{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <PhoneInput
                                            value={data?.phoneNumber || ""}
                                            onChange={e => setData(d => ({ ...d, phoneNumber: e }))}
                                            type="text"
                                            placeholder="Phone number"
                                            className={"form-control" + (errors.phoneNumber ? " error" : "")}
                                            id="phoneNumber"
                                        />
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        {errors.phoneNumber && <span className="form-error-message">{errors.phoneNumber}</span>}
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="form-floating">
                                        <textarea
                                            value={data?.message || ""}
                                            className={"form-control" + (errors.message ? " error" : "")}
                                            onChange={e => setData(d => ({ ...d, message: e.target.value }))}
                                            placeholder="Your Message"
                                            id="textarea"
                                            style={{ height: "100px" }}
                                        />
                                        <label htmlFor="textarea">Your Message</label>
                                        {errors.message && <span className="form-error-message">{errors.message}</span>}
                                    </div>
                                </div>
                                {(fail || success) && (
                                    <div className="col-12">
                                        {success ? <h5 className="text-success">You have sent a message, thank you!</h5> : null}
                                        {fail ? (
                                            <h6 className="text-danger">
                                                <>
                                                    Could not send. Please{" "}
                                                    <a className="text-white" href="mailto:chartering@malibueyachts.com.">
                                                        email
                                                    </a>{" "}
                                                    us.
                                                </>
                                            </h6>
                                        ) : null}
                                    </div>
                                )}

                                <div className="col-12 d-flex justify-content-center justify-content-md-start mb-md-5">
                                    <button disabled={isLoading} onClick={onSubmit} className="button fill dark">
                                        <span>send message</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <Fade delay={400} duration={1500} cascade>
                    <div className="col-12 d-flex flex-column mb-3 col-md-5 order-md-1 mb-md-0 justify-content-md-center ps-inside">
                        <h2 className="pb-2 d-flex align-self-center align-self-md-start">
                            Other ways to
                            <br /> get in touch
                        </h2>
                        <div className="d-flex flex-column justify-content-sm-center">
                            <div className="d-inline-flex">
                                <a
                                    className="mb-2 text-decoration-none d-flex align-items-center"
                                    href="https://www.instagram.com/maliblueyachts/"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="MBY Instagram"
                                    alt="MBY Instagram"
                                >
                                    <FontAwesomeIcon fixedWidth className="me-2" size="2x" icon="fa-brands fa-instagram" />
                                    <span className="fw-bolder ">maliblueyachts</span>
                                </a>
                            </div>
                            <div className="d-inline-flex">
                                <a
                                    className="mb-2 text-decoration-none d-flex align-items-center"
                                    href="https://www.facebook.com/Maliblue-Yachts-102827445682541"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="MBY Facebook"
                                    alt="MBY Facebook"
                                >
                                    <FontAwesomeIcon fixedWidth className="me-2" size="2x" icon="fa-brands fa-facebook" />
                                    <span className="fw-bolder ">Maliblue Yachts</span>
                                </a>
                            </div>
                            <div className="d-inline-flex">
                                <Obfuscate
                                    className="mb-2 text-decoration-none d-flex align-items-center fw-bolder"
                                    email="hello@malibueyachts.com"
                                    aria-label="email"
                                    obfuscateChildren={false}
                                >
                                    <FontAwesomeIcon fixedWidth className="me-2" size="2x" icon={["fas", "envelope"]} />
                                    <span>hello@maliblueyachts.com</span>
                                </Obfuscate>
                            </div>
                            <div className="d-inline-flex">
                                <a
                                    className="mb-2 text-decoration-none d-flex align-items-center"
                                    href="https://wa.me/34673071322"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="MBY Whatsapp"
                                    alt="MBY Whatsapp"
                                    style={{ fontWeight: 400 }}
                                >
                                    <FontAwesomeIcon fixedWidth className="me-2" size="2x" icon="fa-brands fa-whatsapp" />
                                    +34 673 07 13 22
                                </a>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    )
}
export default ContactForm
