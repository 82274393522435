import * as React from "react"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"
import ContactIntro from "../components/contactIntro"
import FleetSwiper from "../components/fleetSwiper"
import ContactForm from "../components/contactForm"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Contact = () => {
    const allYachtsData = useStaticQuery(graphql`
        query AllYachtsContact {
            allYachtsJson {
                nodes {
                    id
                    name
                    specification {
                        length
                        beam
                        draft
                        fuel
                        speed
                        berths
                    }
                    model
                    link
                    description {
                        first
                        second
                    }
                    features {
                        first
                        second
                    }
                    mainImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)
    const yachts = allYachtsData.allYachtsJson.nodes
    const others = yachts.filter(y => y.name !== "default")

    return (
        <Layout>
            <Seo title="Contact" />

            <div className="top-gradient-container">
                <NavBar image />
                <StaticImage className="bg-contact-water" src="../images/water1.png" alt="water" />
                <ContactIntro />
            </div>
            <ContactForm />
            <div className="overflow-x-hidden">
                <FleetSwiper yachts={others} />
            </div>

            <Newsletter />
            <Footer />
        </Layout>
    )
}

export default Contact
