import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const ContactIntro = () => (
    <div className="container-xxl ">
        <div className="row ">
            <div style={{ zIndex: 10 }} className="col-12 col-md-4 order-md-2 position-relative pe-inside">
                <Roll left>
                    <StaticImage imgStyle={{ zIndex: 10 }} className="frame-contact-page" src="../images/frame2.png" alt="frame" />
                </Roll>
            </div>
            <Fade cascad delay={400} duration={1500}>
                <div className="col-12 mb-3 col-md-8 mb-md-0 order-md-1 ps-inside pe-md-5 d-flex flex-column justify-content-center">
                    <h2>Contact Maliblue</h2>
                    <p>
                        Immerse yourself in a unique personalized experience and enjoy the stunning scenery of Ibiza from the comfort
                        of your own private yacht on the Mediterranean Sea. Still want to know more? Our dedicated team will be on hand
                        to assist you with any enquiries you have regarding booking one of our bespoke Yachts for the day.
                    </p>
                </div>
            </Fade>
        </div>
    </div>
)

export default ContactIntro
